import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import LinkIcon from "./LinkIcon";

const CardWrapper = styled.div`
  border-top: 1px solid #999999;
  &:hover {
    background: #20232a;
  }

  @media (max-width: 768px) {
    &:hover {
      background: #282c34;
    }
  }
`;

const StyledLink = styled(Link)`
  color: white;
  /* &:hover {
    color: #61dafb;
  } */
`;

const FixtureContent = styled.div`
  /* display: grid; */
  /* grid-template-columns: 3fr 4fr 2fr 0.5fr 2fr 3fr; */
  display: grid;
  grid-template-columns: 5fr 1fr;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    /* display: flex;
    justify-content: space-around; */
    padding: 0.2rem 0.2rem 0.2rem 1rem;
    font-size: 0.8rem;
  }
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 5fr 6fr 2fr 1fr 2fr;

  @media (max-width: 768px) {
    grid-template-rows: 30px 30px 70px;
    grid-template-columns: 100px 50px 100px;

    /* display: flex;
    flex-direction: column;
    width: 250px; */
  }
`;

const MatchDate = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    grid-row: 1/2;
    grid-column: 1/4;
    padding: 0.2rem;
  }
`;

const MatchName = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    grid-row: 2/3;
    grid-column: 1/4;
    padding: 0.2rem;
  }
`;

const FirstPlayerName = styled.div`
  padding: 1rem;
  text-align: right;

  @media (max-width: 768px) {
    grid-row: 3/4;
    grid-column: 1/2;
    padding: 1rem 0;
    font-size: 1.1rem;
  }
`;

const VS = styled.div`
  padding: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    grid-row: 3/4;
    grid-column: 2/3;
    margin-top: 0.2rem;
  }
`;

const SecondPlayerName = styled.div`
  padding: 1rem;
  text-align: left;

  @media (max-width: 768px) {
    grid-row: 3/4;
    grid-column: 3/4;
    padding: 1rem 0;
    font-size: 1.1rem;
  }
`;

const Live = styled.div`
  display: flex;
  /* padding: 0.5rem; */

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-around;
  }
`;

const FixtureCard = ({ data }) => {
  const first = data.first.replace("※", "");
  const second = data.second.replace("※", "");

  return (
    <CardWrapper>
      <StyledLink>
        <FixtureContent>
          <Info>
            <MatchDate>{data.date}</MatchDate>
            <MatchName>{data.game}</MatchName>
            <FirstPlayerName>{first}</FirstPlayerName>
            <VS>v</VS>
            <SecondPlayerName>{second}</SecondPlayerName>
          </Info>
          <Live>
            {data.note.map((url) => (
              <LinkIcon title={url.name} url={url.link} />
            ))}
          </Live>
        </FixtureContent>
      </StyledLink>
    </CardWrapper>
  );
};

export default FixtureCard;
